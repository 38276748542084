import React from 'react';

const Database = () => {
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h1 className="card-title">TNFD Recommendations and Resources</h1>
          <p className="card-text">
            Here are some key resources related to the Taskforce on Nature-related Financial Disclosures (TNFD) recommendations:
          </p>
          <ul className="list-group">
            <li className="list-group-item">
              <a href="link" target="https://tnfd.global/publication/recommendations-of-the-taskforce-on-nature-related-financial-disclosures/" rel="noopener noreferrer">Taskforce on Nature-related Financial Disclosures (TNFD) Recommendations</a>
            </li>
            <li className="list-group-item">
              <a href="link" target="https://tnfd.global/publication/executive-summary-2/" rel="noopener noreferrer">Executive Summary of the TNFD Recommendations</a>
            </li>
            <li className="list-group-item">
              Guidance on the identification and assessment of nature-related issues: the LEAP approach
            </li>
            <li className="list-group-item">
              Guidance on scenario analysis
            </li>
            <li className="list-group-item">
              Guidance on engagement with Indigenous Peoples, Local Communities and affected stakeholders
            </li>
            <li className="list-group-item">
              Guidance for corporates on science-based targets for nature
            </li>
            <li className="list-group-item">
              Glossary
            </li>
            <li className="list-group-item">
              Getting started with adoption of the TNFD Recommendations
            </li>
            <li className="list-group-item">
              Guidance on biomes
            </li>
            <li className="list-group-item">
              Findings of a high-level scoping study exploring the case for a global nature-related public data facility
            </li>
            <li className="list-group-item">
              Discussion paper on proposed approach to value chains
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Database;
