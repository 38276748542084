import React, { useState } from 'react';
import './ChatBox.css'; // Custom styling
import { Oval } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paperPlane from '../static/images/paper-plane.png'; // Import the image

import { faBars,faPaperPlane, faPlus,faFileUpload,faPaperclip ,faHome, faExclamationTriangle, faDatabase, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


const AttachChatBox = () => {
    const [message, setMessage] = useState('');
    const [askedquestion, setAskedquestion] = useState('');

    const [file, setFile] = useState(null);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleClick = (e) => {
        e.preventDefault();
        console.log('Click on image')
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestData = {
            question: message,
            similarity_top_k: 10 // Constant similarity_top_k value
        };

        if (message) {
            setLoading(true);
            try {
                const response = await fetch('https://asktnfd.app/qauser/query', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestData)
                });

                if (!response.ok) {
                    throw new Error('Failed to send message');
                }

                const responseData = await response.json();
                console.log(responseData);

                // Clear the message input after sending
                setMessage('');
                setResponse(responseData["Response from simple LLM prompting with references"]);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleFileUpload = async (selectedFile) => {
        let formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('chunk_size', '512');
        formData.append('question_answered_extractor', 'false');

        try {
            const response = await fetch('https://chattnfd.app/userupload/upload-or-fetch-pdf/', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const responseData = await response.json();
            console.log(responseData);

            // Clear the file input after uploading
            setFile(null);
        } catch (err) {
            console.error(err);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            handleFileUpload(selectedFile);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <div className="halfsize">
            <div className='row'>
                <h2>Ask Questions with Attachment</h2>
            </div>
            {response && (
                <div className="response-container">
                    <h5>Question: {askedquestion}</h5>
                    <h4>Response:</h4>
                    <pre>{response}</pre>
                </div>
            )}

        {loading && (
                <div className="loading-container">
                    <Oval
                        height={50}
                        width={50}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
       <div className="chatbox">
                <button className="attach-button" onClick={() => document.getElementById('fileInput').click()}><FontAwesomeIcon icon={faPaperclip} /></button>
                <input
                    type="text"
                    placeholder="Ask with media."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                />

<img src={paperPlane} className='submit' onClick={handleClick} alt="Submit" />
<input
                    type="file"
                    className="form-control chatbox-file-input"
                    id="fileInput"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </div>
        </div>
    );
};

export default AttachChatBox;
