import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useAuth } from '../AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const [success, setSuccess] = useState('');
  const { login } = useAuth();


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let formData = new FormData();
    formData.append('username', email); // Assuming `email` is the username
    formData.append('password', password);
  
    try {
      const response = await fetch('https://asktnfd.app/auth/token', {
        method: 'POST',
        headers: {
          // Headers not needed as fetch will handle FormData content type
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Invalid email or password');
      }
  
      const data = await response.json();
      console.log(data);


      localStorage.setItem('token', data.access_token);
      localStorage.setItem('useremail', data.email);
      const token = localStorage.getItem('token');
      login(token);

      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      setError('Invalid email or password');
    }
  };

  return (
<div className="container">
<div className="row justify-content-center align-items-start">
    <div className="col-lg-6 specialbox ">
    <h1>Sign In</h1>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="email" className="form-label text-left">Email address</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>

            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <button type="submit" className="btn btn-primary">Sign In</button>
        </form>
        <div className="m-3">         <a href="/forgot-password">Forget Password?</a>
</div>
<div className="m-3">         <a href="/signup">signup</a>
</div>

    </div></div>
</div>



  );
};

export default Login;
