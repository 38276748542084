import React, { useState } from 'react';
import './ChatBox.css'; // Custom styling
import { Oval } from 'react-loader-spinner';
import paperPlane from '../static/images/paper-plane.png'; // Import the image

const JustChatBox = () => {
    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [askedQuestion, setAskedQuestion] = useState('');
    const [references, setReferences] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            question: question,
            org: 'TNFD', // Constant org value
            similarity_top_k: 10 // Constant similarity_top_k value
        };
        setLoading(true);
        try {
            const response = await fetch('https://asktnfd.app/qa/query', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`Failed to send message: ${response.statusText}`);
            }

            const responseData = await response.json();
            console.log(responseData);

            // Split response and references
            const fullResponse = responseData["Response from simple LLM prompting with references"];
            const [mainResponse, ...referenceParts] = fullResponse.split('References:');
            const formattedReferences = referenceParts.join('References:').split('\n')
                .filter(ref => ref.trim().startsWith('['))
                .map(ref => {
                    const urlMatch = ref.match(/(https?:\/\/[^\s]+)/);
                    return urlMatch ? { text: ref.trim(), url: urlMatch[0] } : null;
                }).filter(ref => ref !== null);

            // Replace text inside ** with bold
            const boldedResponse = mainResponse.trim().replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

            setResponse(boldedResponse);
            setReferences(formattedReferences);

            // Clear the input after sending
            setAskedQuestion(question);
            setQuestion('');
        } catch (err) {
            console.error('Error:', err.message || err);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <div className="halfsize">
            <div className='row'>
                <h2>Ask from Regulations</h2>
            </div>
            {response && (
                <div className="response-container col-">
                    <h5>Question: {askedQuestion}</h5>
                    <h4>Response:</h4>
                    <pre dangerouslySetInnerHTML={{ __html: response }}></pre>
                    {references.length > 0 && (
                        <details>
                            <summary>References</summary>
                            <ul>
                                {references.map((ref, index) => (
                                    <li key={index}>
                                        <a href={ref.url} target="_blank" rel="noopener noreferrer">{ref.text}</a>
                                    </li>
                                ))}
                            </ul>
                        </details>
                    )}
                </div>
            )}

            {loading && (
                <div className="loading-container">
                    <Oval
                        height={50}
                        width={50}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            <form className="chatbox" onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Ask your questions from TNFD."
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <img src={paperPlane} className='submit' onClick={handleSubmit} alt="Submit" />
            </form>
        </div>
    );
};

export default JustChatBox;
