import React from 'react';

const Disclaimer = () => {
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
        <h2 className='card-text'>Disclaimer </h2>
          <p className="card-text">
          The ChatTNFD product is currently in its beta version and undergoes continuous updates to ensure improvements and refinements in both performance and user experience.          </p>
         
         <p className="card-text">The chatbots on www.chattnfd.app provide automated responses generated by a machine-learning algorithm based on academic research and relies on the accuracy of the underlying Language Models (currently OpenAI’s GPT-3.5 and GPT-4). Our academic research is an ongoing process. While we strive to provide accurate and relevant information, we cannot guarantee the accuracy or completeness of the responses.
Please note that there is a chance that ChatTNFDis hallucinating, may occasionally produce harmful instructions or biased content and may occasionally generate incorrect information.
</p>
          <h2>Terms of Use</h2>
        <p>By using ChatTNFD, you agree to the terms and conditions outlined here:</p>
        <ul>
            <li>The chatbots are intended to provide access to research-related information and are not a substitute for professional advice or consultation with qualified experts.</li>
            <li>The chatbots and their responses are intended for educational and informational purposes only and should not be used for commercial or business purposes.</li>
            <li>The creators of the chatbots are not liable for any damages or losses incurred as a result of using the chatbots’ responses.</li>
        </ul>

        <h2>Data Protection Policy</h2>
        <p>We take data protection very seriously; In this statement, we explain which user data are collected, processed, and disclosed by ChatTNFD, and for what purpose, and we describe the measures taken to safeguard the security of these data. All measures conform to the prevailing provisions on data protection.</p>
        <p>Data protection serves to protect the privacy of individuals. It requires data processors to act lawfully, and confers enforceable rights to the affected persons with regard to the processing of their personal data. Section 3 of the Canton of Zurich’s Act on Information and Data Protection (Gesetzüber die Information und den Datenschutz [IDG ZH]) defines personal data as any information related to an identified or identifiable individual. This includes details such as names, addresses, phone numbers, and e-mail or IP addresses.</p>

        <h3>Collecting, Processing, and Storing Data</h3>
        <p>Every time a user interacts with ChatTNFD (writing questions, giving feedback, and writing comments), the following access data are collected and securely stored in a web server file:</p>
        <ul>
            <li>The answer posed</li>
            <li>The feedback given</li>
            <li>The comment written</li>
            <li>The IP address of the requesting computer (e.g. 123.456.97.36)</li>
            <li>The date and time of the request (e.g. [12/Apr/2016:00:00:01 +0200])</li>
            <li>Browser and/or operating system used</li>
            <li>The session ID</li>
            <li>The serial number of the requesting computer</li>
        </ul>
        <p>These data are processed for the following purposes: Safeguarding the network infrastructure and facilitating technical administration Identifying and tracking unauthorized access attempts Web server log files are stored for six months after access is terminated. Once this period has elapsed, web server log files are automatically deleted, provided there has been no identified attack on ChatTNFD’s infrastructure necessitating the civil or criminal prosecution of the intruder and thus requiring that the log file be stored longer.</p>
        <p>Google Cloud Platform is used as a web server and is located in West Europe, europe-west1-b).</p>

        <h3>Consent to Further Use of Data</h3>
        <p>By entering and sending this data, you give your consent for this information to be processed for exclusively for non-commercial research purposes, i.e., for:</p>
        <ul>
            <li>Writing research articles and</li>
            <li>Improving our algorithms.</li>
        </ul>
        <p>For publications and for improving our algorithms, all data is anonymized. No data will be shared with commercial third-party providers.</p>

        <h3>Disclosure of Personal Data</h3>
        <p>Personal data will be disclosed to third parties (e.g. other authorities) only if required by binding legal provisions (e.g. the ruling of an authority, court orders) or for the purposes of legal or criminal prosecution (e.g. in the event of attacks on ChatTNFD’s network infrastructure). Personal data will not be disclosed to third parties for other purposes.</p>

        <h3>Tracking Settings</h3>
        <p>The usage information generated by the cookies, including their abbreviated IP address, will not be forwarded to third parties, but securely stored for the purpose of usage analysis and website optimization. The IP addresses will immediately be anonymized during this process. It is therefore not possible to trace the results of this analysis back to a specific IP address or to monitor the behavior of a data subject.</p>

        <h3>Cookies</h3>
        <p>So-called temporary cookies may be used. Temporary cookies are small files that the ChatTNFD’s web page visited stores on your computer to enable optimal use of the website; the cookies are deleted automatically when the web browser is closed. You can block the use of temporary cookies by adjusting the relevant settings in your web browser. Please note that disabling, blocking, or deactivating cookies can limit the functionality of the ChatTNFD’s web pages.</p>

        <h3>Links to External Websites</h3>
        <p>The data protection statement applies only to ChatTNFD’s web pages. In the case of links providing access to external sites, please refer to the data protection policies of the providers in question. ChatTNFD is not responsible for the content or for the data protection policies of these providers. In particular, ChatTNFD cannot guarantee that content provided by other providers is free of malware.</p>

        <h3>Use of Social Media Plugins</h3>
        <p>The ChatTNFD website does not use additional programs in the form of so-called social media plugins that send IP addresses direct to the providers of social networks. The buttons that appear on the ChatTNFD website are merely links to the corresponding social network providers.</p>

        <h3>Security</h3>
        <p>ChatTNFD undertakes technical and organizational security measures to ensure that the data it collects and processes via the ChatTNFD website remain confidential and are safeguarded from accidental or unauthorized access, changes or disclosure, loss, and destruction; moreover, only the persons who require access to personal data due to their job and work-related duties are granted access on a need-to-know basis.</p>
        <p>The measures selected depend on the type of information, the type and purpose of use, and the technology available.</p>

        <h3>Validity</h3>
        <p>ChatTNFD reserves the right to amend this data protection statement at any time with future effect if the implementation of new technologies or the legal situation so requires. For this reason, we advise you to check the data protection statement on a regular basis.</p>
        <p>We take user privacy seriously and have implemented measures to protect user information. The chatbots may be subject to maintenance and updates, which may affect their functionality. The creators reserve the right to modify, suspend, or discontinue the chatbots at any time without notice. By using the chatbots, you agree to these terms. All data is anonymized and securely stored and protected in compliance with applicable data protection laws.</p>

       
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
